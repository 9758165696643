import React, { useState } from 'react';
import Popover from 'components/Popover';
import Year from './year';
import Make from './make';
import ItemType from './itemType';
import Sort from './sort';
import More from './more';
import Estimate from './estimate';
import styles from './header.module.scss';
import { SORTING_OPTIONS } from 'components/utils/vehiclesFormUtils';
import {
  enabled as moreEnabled,
  getEstimatedValue,
} from 'shared/helpers/vehicles';
import { amplitudeEvents, trackEvent, registerToBidEvent } from 'utils/amplitude';
import { useAuth0 } from '@auth0/auth0-react';
import Button from 'components/Button';

const Header = ({
  vehicleCount,
  filters,
  selectFilter,
  selectSort,
  filtersSelected,
  estimatesRangeData,
  defaultSortId,
  withRegisterButton
}) => {
  const { user, isAuthenticated } = useAuth0();
  const [yearPopoverOpen, setYearPopoverOpen] = useState(false);
  const [sortPopoverOpen, setSortPopoverOpen] = useState(false);
  const [morePopoverOpen, setMorePopoverOpen] = useState(false);
  const [itemTypePopoverOpen, setItemTypePopoverOpen] = useState(false);
  const [makePopoverOpen, setMakePopoverOpen] = useState(false);
  const [estimatePopoverOpen, setEstimatePopoverOpen] = useState(false);
  const yearToggle = () => setYearPopoverOpen(!yearPopoverOpen);
  const sortToggle = () => setSortPopoverOpen(!sortPopoverOpen);
  const moreToggle = () => setMorePopoverOpen(!morePopoverOpen);
  const itemTypeToggle = () => setItemTypePopoverOpen(!itemTypePopoverOpen);
  const makeToggle = () => setMakePopoverOpen(!makePopoverOpen);
  const estimateToggle = () => setEstimatePopoverOpen(!estimatePopoverOpen);

  return (
    <div className={styles.vehiclesHeader}>
      <div className={styles.innerSection}>
        <div className={styles.count}>
          {!!vehicleCount && <p>{vehicleCount} Results </p>}
        </div>
        <div className={styles.mainList}>
          <div className={styles.listWrapper}>
            <Popover
              label="Year"
              id="year"
              popoverOpen={yearPopoverOpen}
              toggle={yearToggle}
              active={!!filtersSelected.filtersInput.year}
            >
              <Year
                initialValues={
                  filtersSelected.filtersInput.year ?? {
                    start: filters.year?.start ?? '',
                    end: filters.year?.end ?? '',
                  }
                }
                update={values => {
                  yearToggle();
                  selectFilter({ year: values });
                }}
                cancel={() => {
                  if (filtersSelected.filtersInput.year)
                    selectFilter({ year: undefined });
                  yearToggle();
                }}
              />
            </Popover>
            <Popover
              label="Make"
              id="make"
              popoverOpen={makePopoverOpen}
              toggle={makeToggle}
              active={!!filtersSelected.filtersInput.make.length}
            >
              <Make
                make={filtersSelected.filtersInput.make}
                makeList={filters.make}
                update={values => {
                  makeToggle();
                  selectFilter({ make: values });
                }}
                cancel={() => {
                  if (!!filtersSelected.filtersInput.make.length)
                    selectFilter({ make: [] });
                  makeToggle();
                }}
              />
            </Popover>
            <Popover
              containerStyle="estimatePopup"
              label="Estimate"
              id="estimate"
              active={!!filtersSelected.filtersInput.estimate}
              popoverOpen={estimatePopoverOpen}
              toggle={estimateToggle}
            >
              <Estimate
                state={
                  filtersSelected.filtersInput.estimate
                    ? {
                        min: filtersSelected.filtersInput.estimate.start,
                        max: filtersSelected.filtersInput.estimate.end,
                      }
                    : estimatesRangeData.initialData
                }
                rangeData={estimatesRangeData}
                update={value => {
                  const minValue = getEstimatedValue(value.min);
                  const maxValue = getEstimatedValue(value.max);

                  estimateToggle();
                  selectFilter({
                    estimate: {
                      start: String(minValue),
                      end: !value.maxValueExceed
                        ? String(maxValue)
                        : String(estimatesRangeData.realMax),
                    },
                  });
                }}
                cancel={() => {
                  if (filtersSelected.filtersInput.estimate)
                    selectFilter({ estimate: undefined });
                  estimateToggle();
                }}
              />
            </Popover>
            <Popover
              label="Item Type"
              id="itemType"
              active={!!filtersSelected.filtersInput.itemType.length}
              popoverOpen={itemTypePopoverOpen}
              toggle={itemTypeToggle}
            >
              <ItemType
                itemTypesList={filters.itemType}
                state={filtersSelected.filtersInput.itemType}
                update={values => {
                  itemTypeToggle();
                  selectFilter({ itemType: values });
                }}
                cancel={() => {
                  if (!!filtersSelected.filtersInput.itemType.length)
                    selectFilter({ itemType: [] });
                  itemTypeToggle();
                }}
              />
            </Popover>
            <Popover
              label="Auction Type"
              active={moreEnabled(filtersSelected.filtersInput)}
              id="vehiclesMore"
              popoverOpen={morePopoverOpen}
              toggle={moreToggle}
            >
              <More
                venueList={filters.venue}
                liveAuctionVenue={filters.liveAuctionVenue}
                auctionTypeList={filters.auctionType}
                auctionYearList={filters.auctionYear}
                onlineAuctionName={filters.onlineAuctionName}
                state={filtersSelected.filtersInput}
                hasReservePrice={filters.hasReservePrice}
                onlineBiddingAvailable={filters.onlineBiddingAvailable}
                update={values => {
                  moreToggle();
                  selectFilter({ ...values });
                  const auctionNames = values?.onlineAuctionName.map(
                    onlineAuction => onlineAuction.split('_')[1]
                  );
                  const eventProperties = {
                    auction_type: values?.auctionType,
                    auction_year: values.auctionYear,
                    reserve: !values?.hasReservePrice ? true : false,
                    online_bidding: values?.onlineBiddingAvailable
                      ? true
                      : false,
                    event:
                      values?.onlineAuctionName?.length > 0
                        ? auctionNames
                        : values?.liveAuctionVenue,
                    email_address: user?.email,
                  };
                  trackEvent(
                    amplitudeEvents.AUCTION_TYPE_FILTER,
                    eventProperties
                  );
                }}
                cancel={values => {
                  if (moreEnabled(filtersSelected.filtersInput))
                    selectFilter({ ...values });
                  moreToggle();
                }}
              />
            </Popover>
            <Popover
              showCaretDown={true}
              active={filtersSelected.sortBy !== defaultSortId}
              label="Sort"
              id="sort"
              popoverOpen={sortPopoverOpen}
              toggle={sortToggle}
            >
              <Sort
                selected={SORTING_OPTIONS.find(
                  option => option.id === filtersSelected.sortBy
                )}
                update={value => {
                  selectSort({ sortBy: value.id });
                  sortToggle();
                }}
                sortOptions={SORTING_OPTIONS}
              />
            </Popover>
            {withRegisterButton &&
              <Button
                handleClick={() => {
                  registerToBidEvent(isAuthenticated, user.email);
                }}
                type="app-primary-button AM-login-button withLeftMargin"
                path="/auth/online-registration"
                linkAsButton={true}
              >
                Register to Bid
              </Button>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
