import React from 'react';

export const KeyIcon = () => (
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.375 2.41675L22.9584 4.83341M13.7629 14.0288L18.7292 9.06258M18.7292 9.06258L22.3542 12.6876L26.5834 8.45841L22.9584 4.83341M18.7292 9.06258L22.9584 4.83341M13.7629 14.0288C14.3869 14.6444 14.8828 15.3774 15.2223 16.1855C15.5618 16.9935 15.7381 17.8608 15.741 18.7373C15.744 19.6137 15.5735 20.4822 15.2394 21.2925C14.9054 22.1028 14.4143 22.8391 13.7945 23.4588C13.1748 24.0786 12.4385 24.5697 11.6282 24.9037C10.8179 25.2378 9.94944 25.4083 9.07296 25.4053C8.19647 25.4024 7.32922 25.2261 6.52115 24.8866C5.71307 24.5471 4.98013 24.0512 4.36453 23.4272C3.15393 22.1738 2.48407 20.4951 2.49921 18.7526C2.51436 17.01 3.21329 15.3432 4.44548 14.111C5.67767 12.8788 7.34453 12.1799 9.08705 12.1647C10.8296 12.1496 12.5083 12.8194 13.7617 14.03L13.7629 14.0288Z"
      stroke="#1C5BAE"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
