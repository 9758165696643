import { useStaticQuery, graphql } from 'gatsby';
export const useStaticQueryGetPrivateVehicles = () => {
  const query = useStaticQuery(
    graphql`
      query getPrivateVehicle {
        PrivateSales: allContentfulPrivateVehicle {
          nodes {
            title
            soldFor
            description {
              childMarkdownRemark {
                html
              }
            }
            backgroundImage {
              fluid(maxWidth: 1200) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            slug
          }
        }
      }
    `
  );
  return { privateVehicle: query.PrivateSales.nodes };
};
