import React from 'react';
import useStaticQueryGetPrivateSales from 'services/graphql/queries/PrivateSales';
import TabsVehiclesList from 'features/TabsVehiclesList';
import Carousel from 'components/Carousel';
import { VehicleCarousel } from 'components/VehicleCarousel';
import { ChooseGooding } from 'features/ChooseGooding';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UsersIcon } from '../../../static/svg/UsersIcon';
import { AwardIcon } from '../../../static/svg/AwardIcon';
import { SheildIcon } from '../../../static/svg/SheildIcon';
import { GlobalIcon } from '../../../static/svg/GlobalIcon';
import { ClipBoardIcon } from '../../../static/svg/ClipBoardIcon';
import { KeyIcon } from '../../../static/svg/KeyIcon';
import { useStaticQueryGetPrivateVehicles } from 'services/graphql/queries/PrivateVehicle';
import HeroSectionCarousel from 'components/HeroSectionCarousel';
import { DescriptiveContactSpecialist } from 'components/DescriptiveContactSpecialist';

const PrivateSales = () => {
  const chooseGoodingData = [
    {
      title: 'FOR SELLERS',
      list: [
        {
          icon: <UsersIcon />,
          text: 'Access to an international pool of potential buyers.',
        },
        {
          icon: <AwardIcon />,
          text:
            'A sale masterfully orchestrated by experts with keen knowledge of and insight on the state of the global economy and collector car market.',
        },
        {
          icon: <SheildIcon />,
          text:
            'A trustworthy process of selling your beloved collector car vehicle with the greatest respect for your privacy',
        },
      ],
    },
    {
      title: 'FOR Buyers',
      list: [
        {
          icon: <GlobalIcon />,
          text:
            " A network of some of the world's most respected and reputable car collectors.",
        },
        {
          icon: <KeyIcon />,
          text:
            'A discreet transaction conducted with the utmost integrity and transparency.',
        },
        {
          icon: <ClipBoardIcon />,
          text:
            'A clear and efficient process with our world-class experts to guide you from start to finish.',
        },
      ],
    },
  ];
  const { privateSalesList } = useStaticQueryGetPrivateSales();
  const { privateVehicle } = useStaticQueryGetPrivateVehicles();
  const soldItems =
    privateSalesList?.length && privateSalesList.filter(item => item.salePrice);
  const availableItems =
    privateSalesList?.length &&
    privateSalesList.filter(item => !item.salePrice);
  return (
    <div>
      {availableItems?.length > 0 && <VehicleCarousel items={availableItems} />}
      <ChooseGooding data={chooseGoodingData} />
      <HeroSectionCarousel heroSections={privateVehicle} privateSales={true} />
      <DescriptiveContactSpecialist />
    </div>
  );
};

export default PrivateSales;
