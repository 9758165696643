import React, { useEffect } from 'react';
import Slider from 'react-slick';
import HeroSection from 'components/HeroSection';
import './heroSectionCarousel.scss';
import { PRIVATE_VEHICLE_PATH } from 'utils/pathsConstants';

const HeroSectionCarousel = ({
  heroSections = [],
  isTextWithOrPrefix,
  privateSales = false,
}) => {
  const settings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };
  useEffect(() => {
    function handleContextMenu(e) {
      e.preventDefault(); // prevents the default right-click menu from appearing
    }
    // add the event listener to the component's root element
    const rootElement = document.getElementById('my-component');
    rootElement.addEventListener('contextmenu', handleContextMenu);
    // remove the event listener when the component is unmounted

    return () => {
      rootElement.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  if (typeof window !== 'undefined') {
    document.addEventListener('contextmenu', e => {
      if (e.target.tagName === 'IMG') {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }
  return (
    <div
      id="my-component"
      className={
        privateSales ? 'private-sales-carousel' : 'hero-section-carousel'
      }
    >
      <div>
        <Slider {...settings}>
          {heroSections?.length &&
            heroSections.map(
              !privateSales
                ? ({ title, description, image, callToAction }, index) => (
                    <HeroSection
                      key={`home-section-carousel_${index}`}
                      image={image}
                      title={title}
                      callToActions={callToAction}
                      description={description?.childMarkdownRemark?.html}
                      isTextWithOrPrefix={isTextWithOrPrefix}
                    />
                  )
                : (
                    { title, description, backgroundImage, soldFor, slug },
                    index
                  ) => (
                    <HeroSection
                      key={`home-section-carousel_${index}`}
                      image={backgroundImage}
                      subTitle={
                        soldFor
                          ? `SOLD FOR: $${Number(soldFor).toLocaleString()}`
                          : ''
                      }
                      title={title}
                      description={description?.childMarkdownRemark?.html}
                      privateSales={true}
                      slug={PRIVATE_VEHICLE_PATH(slug)}
                    />
                  )
            )}
        </Slider>
      </div>
    </div>
  );
};

export default HeroSectionCarousel;
