import Button from 'components/Button';
import OpenFormAction from 'components/CTA/OpenFormAction';
import { ListCard } from 'components/ListCard';
import React from 'react';
import styles from './chooseGooding.module.scss';
export const ChooseGooding = ({ data }) => (
  <div className={styles.container}>
    <h1>Why Choose Gooding?</h1>

    <OpenFormAction
      buttonStyle="app-primary-button"
      extraStyle={`${styles.button}`}
      callToAction={{
        style: 'Button',
        buttonText: 'Contact a Specialist',
        action: { form: 'contact-us' },
      }}
    />
    <div className={styles.lists}>
      {data.map((item, index) => (
        <ListCard title={item.title} lists={item.list} />
      ))}
    </div>
  </div>
);
