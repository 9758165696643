import React from 'react';

export const NextArrow = ({ disable }) => (
  <svg
    width="33"
    height="24"
    viewBox="0 0 33 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.3165 0.360943C21.8708 -0.120314 21.1292 -0.120314 20.668 0.360943C20.2223 0.825942 20.2223 1.59986 20.668 2.06377L29.0268 10.786H1.44382C0.800829 10.7871 0.289764 11.3204 0.289764 11.9913C0.289764 12.6623 0.800829 13.2129 1.44382 13.2129H29.0268L20.668 21.9189C20.2223 22.4001 20.2223 23.1751 20.668 23.6391C21.1292 24.1203 21.8719 24.1203 22.3165 23.6391L32.6541 12.852C33.1153 12.387 33.1153 11.613 32.6541 11.1491L22.3165 0.360943Z"
      fill={'#1C5BAE'}
    />
  </svg>
);
