import VehicleCard from 'components/VehicleCard';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import {
  getAuction,
  getContentfulItemTitle,
  getPrice,
  getTag,
} from 'shared/helpers/vehicles';
import { combineCloudinaryWithImages } from 'utils/image';
import { NextArrow } from '../../../static/svg/NextArrow';
import styles from './vehicleCarousel.module.scss';

export const VehicleCarousel = ({ items }) => {
  const NextArrowCarousel = ({ onClick }) => {
    return (
      <div className={`${styles.nextArrow} `} onClick={onClick}>
        <NextArrow />
      </div>
    );
  };
  const PrevArrowCarousel = ({ onClick }) => {
    return (
      <div className={styles.prevArrow} onClick={onClick}>
        <NextArrow />
      </div>
    );
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: items.length < 3 ? items.length : 3,
    slidesToScroll: 3,
    prevArrow: <PrevArrowCarousel />,
    nextArrow: <NextArrowCarousel />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: items.length < 2 ? items.length : 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: items.length < 1 ? items.length : 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className={styles.vehicleCarouselContainer}>
      <h1>cars available for sale</h1>
      <Slider className={styles.slider} arrows={true} {...settings}>
        {items?.length > 0 &&
          items.map((vehicle, index) => (
            <VehicleCard
              key={`vehicleCard_${index}`}
              fluidImages={combineCloudinaryWithImages(vehicle, 500, 3).slice(
                0,
                3
              )}
              type={'privateSales'}
              tag={getTag(vehicle)}
              makeModel={getContentfulItemTitle(vehicle)}
              auction={getAuction(vehicle)}
              price={getPrice(vehicle, vehicle?.currency)}
              slug={vehicle.slug}
              arrowCircle={false}
              showDotsOnHover={true}
              salesForceId={vehicle?.salesForceId}
              onlineBiddingAvailable={vehicle.onlineBiddingAvailable}
              auctionSlug={
                vehicle.auction?.webpage__auction
                  ? vehicle.auction?.webpage__auction[0].slug
                  : ''
              }
            />
          ))}
      </Slider>
    </div>
  );
};
