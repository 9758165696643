import React from 'react';
import styles from './listCard.module.scss';
export const ListCard = ({ title, lists }) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <h1>{title}</h1>
    </div>
    <div className={styles.list}>
      <ul>
        {lists.map((listItem, index) => (
          <li>
            {listItem.icon}
            <p>{listItem.text}</p>
          </li>
        ))}
      </ul>
    </div>
  </div>
);
